@import 'assets/scss/variables.module';

.grid {
  display: grid;
  justify-items: center;
  gap: 25px;

  & > * {
    width: 100%;
    max-width: 325px;
    min-width: 260px;

    min-height: 111px;

    display: none; // Hide all by default then show them based on how many items you want to show vs the max number of rows you want
  }

  grid-template-columns: repeat(5, 1fr);

  @include response-min-x-large {
    & > *:nth-child(-n + 5) {
      display: unset;
    }
  }

  @include response-large-to-x-large {
    grid-template-columns: repeat(4, 1fr);

    & > *:nth-child(-n + 4) {
      display: unset;
    }
  }

  @include response-medium-to-large {
    grid-template-columns: repeat(3, 1fr);

    & > *:nth-child(-n + 3) {
      display: unset;
    }
  }

  @include response-small-to-medium {
    grid-template-columns: repeat(2, 1fr);

    & > *:nth-child(-n + 4) {
      display: unset;
    }
  }

  @include response-small {
    grid-template-columns: repeat(1, 1fr);

    & > *:nth-child(-n + 3) {
      display: unset;
    }

    & > * {
      max-width: 90%;
    }
  }
}
