@import 'assets/scss/variables.module.scss';

.container {
  height: 40vh;
  max-height: 700px;
  width: 100%;
  position: relative;
  display: flex;

  background-color: #dfddda;

  // background-image: linear-gradient(rgba($archidekt-orange, 0.4) 2px, transparent 2px),
  //   linear-gradient(90deg, rgba($archidekt-orange, 0.5) 2px, transparent 2px),
  //   linear-gradient(rgba($archidekt-orange, 0.3) 1px, transparent 1px),
  //   linear-gradient(90deg, rgba($archidekt-orange, 0.4) 1px, transparent 1px);

  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;

  transition: background-color 0.2s ease-in;
}

body:global(.dark-mode) .container {
  background-color: #1b1a18;

  // background-image: linear-gradient(rgba($archidekt-orange, 0.2) 2px, transparent 2px),
  //   linear-gradient(90deg, rgba($archidekt-orange, 0.1) 4px, transparent 3px),
  //   linear-gradient(rgba($archidekt-orange, 0.1) 1px, transparent 1px),
  //   linear-gradient(90deg, rgba($archidekt-orange, 0.2) 1px, transparent 1px);
}

.background {
  position: absolute;
  top: 0;

  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: center 37%;

  transition: background 1s ease-in-out, background-image 0.2s ease;

  // This filter extends outside of it's parent, so you _must_ have overflow: hidden on parent (@see the above container)
  // filter: blur(8px);
  filter: brightness(0.8);
}

body:global(.dark-mode) .background {
  filter: brightness(0.3);
}

.gradientMask {
  position: absolute;

  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  transition: background 0.25s ease-in;
}

.topGradientMask {
  @extend .gradientMask;

  top: 0;
  height: 200px;
  max-height: 40%;
  background: linear-gradient(180deg, $framing-color 0, rgba(161, 161, 161, 0) 78%);
}

.bottomGradientMask {
  @extend .gradientMask;

  height: 200px;
  max-height: 40%;

  bottom: 0;
  background: linear-gradient(180deg, rgba(161, 161, 161, 0) 0%, $background-color 90%);
}

.sketch {
  position: absolute;
  user-select: none;
  z-index: 0;
}

body:global(.dark-mode) .sketch {
  filter: invert(0.95);
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 1;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
  margin-top: 10px;
  padding: 1rem 2rem;
}

.buttons {
  display: flex;
  gap: 1rem;

  > :first-child {
    background-color: $archidekt-orange;
    color: white;
  }

  & > button,
  a {
    background-color: $light-background2;
    color: $color;
    border: 1px solid $light-background2;
    max-width: 240px;
    font-weight: bold;
    box-shadow: $generic-box-shadow;

    border: none;
    border-radius: 5px;
    padding: 10px 15px;

    transition: filter 0.2s ease;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }
  }
}
