.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fragments {
  transition: width 0.2s ease-in-out;

  height: 100%;
  position: relative;

  border: none;
}

.bar {
  content: '';

  width: 90%;
  height: 15px;

  position: absolute;
  bottom: 5px;
  left: 5%;

  @extend .fragments;
  @extend .center;
}

.left {
  clip-path: polygon(0 0, 100% 0, calc(100% - 7px) 100%, 0% 100%);
  float: left;

  @extend .fragments;
  @extend .center;
}

.right {
  clip-path: polygon(7px 0px, 100% 0, 100% 100%, 0% 100%);

  @extend .fragments;
  @extend .center;
}

.middle {
  clip-path: polygon(7px 0px, 100% 0, calc(100% - 7px) 100%, 0% 100%);

  @extend .fragments;
  @extend .center;
}

.solid {
  @extend .fragments;
  @extend .center;
}

.barIsButtons {
  transition: filter 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }
}
