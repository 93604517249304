@import 'assets/scss/variables.module.scss';

.patreonHidden {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $light-background2;
  color: $light-color;

  padding: 15px;
  border-radius: 5px;
  border: 1px solid $light-color2;

  position: absolute;
}

.patreonLink {
  text-align: center;
}

.hideOnMobile {
  @include response-small {
    display: none !important;
  }
}
