@import 'assets/scss/variables.module';

.table {
  border: 1px solid $light-color2;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;
}

.row {
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  padding: 2px 25px;

  background-color: $light-background;

  &:nth-child(even) {
    background-color: $light-background2;
  }
}

.tagName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.2s ease;

  &:hover {
    color: $archidekt-orange;
  }
}

.data {
  display: flex;
  white-space: nowrap;
}

.percent {
  width: 36px;
  text-align: right;
}
