@import 'assets/scss/variables.module';

.card {
  position: relative;
  height: 105px;
  border: 1px solid $tertiary-color;
  border-left: 4px solid $archidekt-orange;
  border-radius: 5px;
  box-shadow: $generic-box-shadow;
  padding: 10px 15px;
  background-color: $light-background;

  transition-property: background-image, background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  overflow: hidden;

  &:hover {
    cursor: pointer;

    background-color: $light-background3;
    color: $archidekt-orange;

    // Removed orange gradient, leaving it if we want to add it back later
    // background-image: linear-gradient(to left, rgba($archidekt-orange, 0.4), transparent 70%);
  }
}

.image {
  position: absolute;
  top: 0;
  right: -10px;
}

body:global(.dark-mode) .image {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.grid {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 100%;

  background-color: transparent;

  $line-color: rgb(150, 150, 150, 0.1);

  background-image: repeating-linear-gradient(0deg, $line-color, $line-color 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(90deg, $line-color, $line-color 1px, transparent 1px, transparent 10px);
  mask-image: linear-gradient(148deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 35%, rgba(0, 0, 0, 1) 100%);
  -webkit-mask-image: linear-gradient(
    148deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 35%,
    rgba(0, 0, 0, 1) 100%
  );
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;

  color: $color;
}

.points {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding-right: 10px;

  color: $color;

  @include flex-end;
}

.viewAll {
  margin-top: 1rem;

  color: $link-color;
}
