@import 'assets/scss/variables.module';

.tabs {
  display: flex;
  justify-content: flex-start;
  gap: 25px;

  margin-bottom: 1rem !important;

  @include response-small {
    justify-content: center;
  }

  a {
    padding: 1rem;
    opacity: 0.6;
    font-size: 16px;
    transition: color 0.2s ease, background-color 0.2s ease;
    border-radius: 5px;

    &:hover {
      opacity: 1;
      color: $archidekt-orange;
      background-color: $light-background2;
      cursor: pointer;
    }
  }
}

.selected {
  font-weight: bold;
  opacity: 1 !important;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: $archidekt-orange;
    border-radius: 10px;
  }
}
