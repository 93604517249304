@import 'assets/scss/variables.module';

.container {
  @include flex-start;
  flex-direction: column;
  margin: 0 !important;
  gap: 20px;

  padding-bottom: 100px;
}

.containerWidth {
  width: 1725px;
  max-width: 90%;
  margin-bottom: 50px;

  @include response-x-large {
    width: 1345px;
  }

  @include response-large {
    width: 1025px;
  }

  @include response-medium {
    width: 90%;
  }
}

.tagInfo {
  display: flex;
  justify-content: space-between;
  gap: 25px;

  & > * {
    width: 100%;
    max-width: 33%;
  }

  @include response-medium-small {
    flex-wrap: wrap;

    & > * {
      max-width: 100%;
    }
  }
}

.contestDecks {
  display: grid;
  justify-items: center;
  gap: 25px;

  & > * {
    width: 100%;
    min-width: 220px;
  }

  grid-template-columns: repeat(3, 1fr);

  @include response-medium-small {
    grid-template-columns: repeat(1, 1fr);
  }
}
