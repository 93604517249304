@import 'assets/scss/variables.module';

.container {
  @include flex-around;
  align-items: center;

  h3 {
    margin: 0 !important;
    padding: 5px 0;
  }

  & > a,
  & > button {
    font-weight: bold;
    transition: color 0.2s ease;
    background: transparent;
    border: none;
    color: $color;
    font-size: 14px;

    &:hover {
      color: $archidekt-orange;
      cursor: pointer;
    }
  }

  @include response-small {
    position: sticky;
    top: -2px;
    z-index: 1;

    background: $background-color;
  }
}
