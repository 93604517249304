@import 'assets/scss/variables.module';

.container {
  position: relative;
}

.trophy {
  position: absolute;
  top: -5px;
  right: -15px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
  border-radius: 50%;
  border: 1px solid $light-color2;
  box-shadow: $generic-box-shadow;

  pointer-events: none;

  i {
    margin-top: 2px;
    margin-right: -1px !important;
    font-size: 20px !important;
  }
}
