@import 'assets/scss/variables.module';

.cardGridUnlocked {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;

  @include response-large-to-x-large {
    grid-template-columns: repeat(4, 1fr);
  }

  @include response-medium-to-large {
    grid-template-columns: repeat(3, 1fr);
  }

  @include response-small-to-medium {
    grid-template-columns: repeat(2, 1fr);
  }

  @include response-small {
    grid-template-columns: 1fr;
  }

  @include response-medium {
    & > * {
      max-width: 90%;
    }
  }
}

.cardGrid {
  @extend .cardGridUnlocked;

  & > * {
    display: none; // Hide all by default then show them based on how many items you want to show vs the max number of rows you want
  }

  @include response-min-x-large {
    & > *:nth-child(-n + 10) {
      display: flex;
    }
  }

  @include response-large-to-x-large {
    & > *:nth-child(-n + 8) {
      display: flex;
    }
  }

  @include response-medium-to-large {
    & > *:nth-child(-n + 9) {
      display: flex;
    }
  }

  @include response-medium {
    & > *:nth-child(-n + 8) {
      display: flex;
    }
  }
}

.card {
  width: 100%;

  // display: flex; // Set by .cardGrid itself
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  position: relative;
}

.basicCard {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 223px;

  & > button:first-child {
    box-shadow: $generic-box-shadow;
    pointer-events: none;
  }
}

.controls {
  border-radius: 5px;
  border: 1px solid $light-color2;
  background-color: $light-background;
  box-shadow: $generic-box-shadow;

  margin-top: 65px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 250px;
  gap: 4px;

  button,
  a {
    white-space: nowrap;
    width: 100%;
    max-width: 250px;
    border-radius: 5px;
    height: 35px;

    border: 1px soluid $light-color2;
    font-weight: bold;

    color: $color;
    background-color: $light-background2;
    border: 1px solid $light-color2;
    box-shadow: $generic-box-shadow;
    transition: 0.2s background-color ease, 0.2s opacity ease;

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    gap: 6px;

    &:hover:enabled {
      cursor: pointer;
      background-color: $light-background;
    }

    &:disabled {
      opacity: 0.6;
    }

    i {
      margin-right: 0 !important;
    }
  }

  a:hover {
    cursor: pointer;
    background-color: $light-background;
  }
}

.gradient {
  position: absolute;

  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  transition: background 0.25s ease-in;

  top: 74px;
  height: 200px;
  max-height: 40%;
  background: linear-gradient(180deg, $background-color 0, rgba(161, 161, 161, 0) 78%);
}

.quantities {
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  width: 100%;
  max-width: 220px;
  margin-bottom: 8px;

  color: $light-color;
}

.spacer {
  height: 232px;
}

.flipButton {
  position: absolute;
  left: -5px;
  top: 80px;

  width: 35px;
  height: 35px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #6435c9; // ripping off semantic purple here
  color: white;
  border: 1px solid $light-color;

  cursor: pointer;

  i {
    margin-right: 0 !important;
  }
}
