@import 'assets/scss/variables.module';

$border-radius: 5px;

.newsCardContainer {
  position: relative;
  border: 1px solid $tertiary-color;
  padding: 5px 10px;
  background-color: $light-background;
  border-radius: $border-radius;
  box-shadow: $generic-box-shadow;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $light-background2;

    .newsImage {
      opacity: 1;
    }
  }
}

.newsImage {
  position: absolute;
  left: 0;
  top: 0;

  border-radius: $border-radius;

  height: 100%;
  width: 33%;

  background-position: center;
  background-size: cover;

  mask-image: linear-gradient(to right, black 25px, transparent 100%);

  opacity: 0.7;
  transition: opacity ease-in-out 0.25s;

  img {
    height: 100%;
  }
}

.newsHeader {
  color: $color;
  margin: 10px 0 10px 0;
  font-weight: bold;
  font-size: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: color 0.2s ease;

  &:hover {
    color: $archidekt-orange;
  }
}

.newsDescription {
  color: $light-color;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsText {
  margin-left: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 8px;
}

.newsMeta {
  display: flex;
  justify-content: space-between;
}

.newsDate {
  color: $light-color;
  font-size: 75%;
}

.newsAuthor {
  color: $light-color;
  font-size: 75%;

  transition: color 0.2s ease;

  &:hover {
    color: $archidekt-orange;
  }
}

.newsTag {
  height: 16px;
  color: #fff;

  font-size: 80%;

  position: absolute;
  bottom: 8px;
  left: 12px;

  border-radius: 8px;
  display: flex;
  text-align: center;
  align-items: center;

  padding: 5px;
  padding-top: 3px;
}
